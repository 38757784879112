export default function Privacy() {
  return (
    <>
      <h3 className="title">Softdebut Co., Ltd Thailand Privacy Policy</h3>
      <p className="subTitle">Updated 13th October 2023</p>

      <p>
        This Privacy Policy (the “<span className="boldText">Policy</span>”)
        outlines how Softdebut Co., Ltd Thailand (“
        <span className="boldText">Company</span>”, “
        <span className="boldText">we</span>”, “{" "}
        <span className="boldText">us</span>” “
        <span className="boldText">our</span>”) collects, uses, stores and
        discloses your Personal Data (defined below) when using our Services.
        This Policy should be read together with, and forms part of, our Terms
        and Conditions of Service (the “<span className="boldText">Terms</span>
        ”), which are available on the Site. Unless otherwise defined herein,
        capitalised terms in this Policy have the meaning given to them in the
        Terms.
      </p>
      <p>
        We recognise the importance of protecting Personal Data and we are
        committed to complying with applicable data protection laws. Please read
        this Policy so that you know and understand the purposes for which we
        collect, use, store and disclose Personal Data.
      </p>
      <p>
        The Policy is provided in order to inform you of information relating to
        the processing of your Personal Data and your rights under the laws. You
        are encouraged to thoroughly read the Policy before accessing and using
        the Site, using our Services, interacting with us or providing your data
        to us. If you have any concerns with any aspect of this Policy, you
        should immediately contact us using the information provided in Contact
        Us section below.
      </p>
      <p>
        We may from time to time update this Policy to ensure that it is
        consistent with future developments, industry trends and/or any changes
        in legal or regulatory requirements. Please check back regularly for
        updated information on the handling of your Personal Data.
      </p>
      <p>
        For purposes of this Policy, “
        <span className="boldText">Personal Data</span>” means any information
        that directly or indirectly identifies a particular individual,
        including any other information that is subject to applicable data
        protection laws.
      </p>
      <h3>Scope</h3>
      <p>
        This Policy applies to our collection, use and disclosure of Personal
        Data related to the Users of our Services, including on our Site or
        Platform. This Policy does not apply to job applicants or to Company
        employees and non-employee contractors, whose Personal Data is subject
        to different privacy notices.
      </p>
      <h3>Collection of Personal Data</h3>
      <p>
        The Personal Data we collect varies depending upon the nature of the
        Services provided or used, and our interactions with individuals.
      </p>
      <h3>Types of Personal Data Collected </h3>
      <p>
        While the Personal Data we collect varies depending upon the
        circumstances and Services you use, we collect the following categories
        of Personal Data:
      </p>
      <ul>
        <li>
          Name, contact information and other identifiers: identifiers such as
          name, address, email address, Discord id, blockchain wallet id,
          Twitter handle and online identifiers.
        </li>
        <li>
          Customer records: electronic customer records containing Personal
          Data.
        </li>

        <li>
          Device information: internet protocol (IP) address, web browser type,
          operating system version, phone carrier and manufacturer, application
          installations, device identifiers, and mobile advertising identifiers.
        </li>
        <li>
          Communications and requests: direct communication, web forms, online
          polls, or interactions with our Services, the Site and the Platform.
          We collect and maintain a record of your contact details,
          communications and our responses.
        </li>
        <li>
          User Content: if you post Content on the Site or Platform or otherwise
          provide Content in the course of using our Services, if you interact
          with any other User, or if you submit feedback and comments to us, we
          may maintain a record of your User Content, including the location,
          date and time of submission, as well as any other information
          contained in that User Content.
        </li>

        <li>
          Transaction information: including public keys, wallet addresses,
          records of Virtual Assets or any goods or services sold, purchased,
          traded or otherwise dealt with.
        </li>
        <li>Cookie data: as explained in more detail later in this Policy.</li>
        <li>
          Marketing, surveys and events: if you sign up to receive news,
          updates, offers and other marketing communications from us, we may
          collect your name, contact information and information about your
          preferences.
        </li>
        <li>
          Location data: location information about a particular individual or
          device, general location information (for example, your IP address may
          indicate your more general geographic region).
        </li>
        <li>
          Profiles and inferences: inferences drawn from any of the information
          identified above to create a profile reflecting a User’s preferences.
        </li>
      </ul>
      <h3>Sources of Personal Data </h3>
      <p>We may collect Personal Data:</p>
      <ul>
        <li>
          Directly from you: such as when you use our Services, make a
          transaction, participate in promotions we offer, register to receive
          marketing and other communications from us, or contact us for customer
          support purposes. If you submit any Personal Data relating to other
          people to us, you represent that you have the authority to do so and
          have informed that other person about the contents of this Policy.
        </li>
        <li>
          From third parties: we may collect information about Users from openly
          available information on the internet. This includes Ethereum
          blockchain openly available data from
          <a href=" https://etherscan.io/."> https://etherscan.io/.</a>
        </li>
        <li>
          Related to your use of our Services: including information we collect
          automatically when you use our Services or interact with us or
          information we derive about you and your preferences or interests
          based on the Personal Data we collect and our interactions with you.
          This information may be collected via cookies when you use our
          Services and you can find more details about our use of cookies below.
        </li>
      </ul>
      <p>
        In the event your Personal Data is required for compliance with law or
        contracts to which you are a party, or for proceeding with requests or
        applications made by you to us, we will inform you of the consequences
        of declining to provide your Personal Data to us, and where your
        Personal Data has not been provided, we will not enter into any
        contracts with you and/or process any request or application made by you
        to us.
      </p>
      <h3>Use of Personal Data</h3>
      <p>
        We and our authorised third parties will only process your Personal Data
        where we have the legal grounds to do so. We may use Personal Data for a
        variety of purposes, as follows:
      </p>
      <ul>
        <li>
          Providing support and services: to provide and operate our Services,
          to respond to your inquiries, to provide troubleshooting, to fulfill
          your transactions and requests, to process your payments and to
          provide technical support; and for other customer service and support
          purposes. Our lawful basis is to fulfil any contractual terms with
          you.
        </li>
        <li>
          Analyzing and improving our business: to better understand how Users
          access and use our Services, to evaluate and improve our Services and
          business operations, and to develop new features, offerings, and
          services; to conduct surveys and other evaluations (such as customer
          satisfaction surveys); to monitor consumer interest in our products
          and Services; to troubleshoot problems that may arise on the Site or
          Platform; to improve the accuracy of our customer database; to
          increase our understanding of our customer database; to increase our
          understanding of our customers; and for other research and analytical
          purposes. Our lawful basis is our legitimate business interests in
          understanding and improving our Services or consent where we use
          cookies or similar technology that are not strictly necessary.
        </li>
        <li>
          Personalizing content and experiences: to tailor content we send or
          display on our Services and to otherwise personalize your experiences.
          To assist in these purposes, as well as the other purposes identified
          in this Policy, at certain times, we create a profile relating to you
          in order to show you the content that we think you might be interested
          in and to display the content according to your preferences. Our
          lawful basis is our legitimate business interests in offering a more
          personalized service or consent where we use cookies or similar
          technology that are not strictly necessary.
        </li>
        <li>
          Advertising, marketing and promotional purposes: to reach you with
          more relevant advertising and to evaluate, measure and improve the
          effectiveness of our ad campaigns; to send you newsletters, offers or
          other information we think may interest you; to contact you about our
          Services, products, or other information we think may interest you;
          and to identify potential new customers. Our lawful basis is your
          consent to subscribe to any newsletter or marketing (and you can
          unsubscribe at any time) and consent where we use cookies or similar
          technology that are not strictly necessary.
        </li>
        <li>
          Securing and protecting our business: to protect and secure our
          business operations, assets and Services; to investigate, prevent,
          detect and take action regarding fraud, unauthorized access,
          situations involving potential threats to the rights or safety of any
          person or third-party, or other unauthorized activities or misconduct.
          Our lawful basis is our legitimate business interests in protecting
          our business and Services.
        </li>

        <li>
          Defending our legal rights: to manage and respond to actual and
          potential legal disputes and claims, and to otherwise establish,
          defend or protect our rights or interests, including in the context of
          anticipated or actual litigation with third parties. Our lawful basis
          is our legitimate business interests in protecting our business or our
          need to defend ourselves legally.
        </li>
        <li>
          Auditing, reporting, corporate governance, and internal
          operations: relating to financial, tax and accounting audits; audits
          and assessments of our operations, privacy, security and financial
          controls, risk, and compliance with legal obligations; our general
          business, accounting, record keeping and legal functions; and relating
          to any actual or contemplated merger, acquisition, asset sale or
          transfer, financing, bankruptcy or restructuring of all or part of our
          business. Our lawful basis is our legal obligations under relevant
          legislation such as tax, reporting and/or our legitimate interests in
          running our compliance programmes
        </li>
        <li>
          Complying with legal obligations: to comply with the law, our legal
          obligations and legal process, such as warrants, subpoenas, court
          orders, and regulatory or law enforcement requests. Our lawful basis
          is compliance with applicable law.
        </li>
      </ul>
      <p>
        Where we have indicated above that we rely on legitimate interests for
        processing of Personal Data, we carry out assessments of likely adverse
        effects and if required a ‘balancing’ test to ensure that our processing
        is necessary and that your fundamental rights of privacy are not
        outweighed by our legitimate interests, before we go ahead with such
        processing. We may also carry out a data protection impact assessment
        for processing that is likely to result in a high risk to you. You can
        contact us for more details on our assessments using the details in the
        Contact Us section below.
      </p>
      <h3>Aggregate and De-identified Data </h3>
      <p>
        We may de-identify information and create anonymous and aggregated data
        sets and reports to assess, improve and develop our business, products
        and Services, prepare benchmarking reports on our industry and for other
        research, marketing and analytics purposes.
      </p>
      <h3>Disclosure of Personal Data</h3>
      <p>
        We may share or disclose the Personal Data we may collect as follows:
      </p>
      <ul>
        <li>
          Service providers: We may disclose Personal Data to third-party
          service providers who use this information to perform services for us,
          such as developers, auditors, advisors, consultants, customer service
          and/or support providers.
        </li>
        <li>
          Advertising and Marketing Partners: We may share Personal Data with
          third parties that provide advertising, campaign measurement, online
          and/or mobile analytics, and related services. This is subject to any
          consents you give for direct marketing and cookies.
        </li>
        <li>
          Subsidiaries, Affiliates, and business partners: We may share your
          Personal Data with our Affiliates and other businesses we have
          partnered with to offer exclusive content on our Services; they may
          use such Personal Data for the purposes set out in this Policy.
        </li>
        <li>
          Legal compliance: We may be required to share Personal Data in
          response to a valid court order, subpoena, government investigation,
          or as otherwise required by law. We also reserve the right to report
          to law enforcement agencies any activities that we, in good faith,
          believe to be unlawful.
        </li>

        <li>
          Business transfers: We may disclose and/or transfer Personal Data as
          part of any actual or contemplated merger, sale, transfer of assets,
          acquisition, financing and/or restructuring of all or part of our
          business, bankruptcy or similar event, including related to due
          diligence conducted prior to such event where permitted by law.
        </li>

        <li>
          Protect our rights: We may disclose Personal Data where we believe it
          necessary to respond to claims asserted against us, to enforce or
          administer our agreements and terms, for fraud prevention, risk
          assessment, investigation and/or to protect the rights, property or
          safety of the Company or our Affiliates, partners, clients, customers
          and/or others.
        </li>
      </ul>
      <p>
        We ensure third parties who receive your Personal Data will handle your
        Personal Data as required by applicable data protection laws, such by
        putting in place appropriate contracts with third parties to protect
        your Personal Data.
      </p>
      <h3>Automated Decisions</h3>
      <p>
        Automated decisions are those made without human intervention that have
        a legal effect on you or other similarly significant effect. We do not
        carry out this type of processing activity.
      </p>
      <h3>Cookies and Analytics</h3>
      <p>
        Cookies are small alphanumeric identifiers or small data text files that
        are sent from a server during a browsing session and are placed on your
        computer. When we refer to “cookies” we include other technologies with
        similar purposes, such as pixels, tags and identifiers. We may use
        cookies or similar technologies, which may be provided by third parties,
        on our Services to enable certain functionality and for security and
        fraud detection and prevention, as well as to collect usage information
        about our Services and the emails that we send, and to personalize
        content and provide more relevant ads and information. We may combine
        the information we collect via these technologies with other
        information, including Personal Data.
      </p>
      <h3>
        Personal Data of Minors, Incompetent Persons, or Quasi-Incompetent
        Person
      </h3>
      <p>
        Following the Terms, our Services are restricted for Users under 13 and
        they are not allowed to use our Services. The Company does not knowingly
        collect or maintain Personal Data from children we actually know at the
        time of collection are under the age of 13. Should we discover that we
        have collected Personal Data online from a child who is under 13, we
        will promptly delete that Personal Data. If you have concerns over the
        collection of children's Personal Data in our Services, please contact
        us at the information provided in the Contact Us section below.
      </p>
      <p>
        If you are above the age of 13 but below the age of 20, or if you are an
        Incompetent Person or Quasi-Incompetent Person (as defined under the
        Civil and Commercial Code of Thailand), we may require the written
        consent of your parent, guardian or custodian (as applicable) to process
        your personal data on top of the consent expressly provided by you.
        Until such written consent has been provided to us, you are not allowed
        to and should not use our Services.
      </p>
      <h3>Security</h3>
      <p>
        The security of your Personal Data is important to us. We have put in
        place safeguards to protect the Personal Data we collect from
        unauthorized access, use and disclosure, and we take steps to ensure
        that all of our employees, agents, contractors and other third parties
        have similar adequate measures in place. We also have procedures to deal
        with any suspected Personal Data breach, and we will notify you and any
        applicable regulator when we are legally required to do so. However, we
        cannot guarantee that unauthorized access, hacking, data loss, or other
        breaches will never occur. We urge you to take steps to keep your
        Personal Data safe, such as choosing a strong password and logging out
        of your User account and closing your web browser when you have finished
        using the Services.
      </p>
      <h3>Transfers Outside your Home Country</h3>
      <p>
        We may transfer your Personal Data to countries outside of your home
        country. The privacy laws in countries outside your home country may be
        different from those in your home country. If we transfer your Personal
        Data to countries outside of your home country, we will take steps to
        comply with the requirements for such transfer in your home country as
        required by relevant law.
      </p>
      <h3>Links to Third-party Websites</h3>
      <p>
        Our Services provide links to various websites operated by third parties
        including, but not limited to, third-party sites that may display
        Company trademarks. This Policy does not apply to third-party websites
        that are accessible through the Services, unless such website directs
        Users or visitors to this Policy. When you click on one of these links,
        you will be transferred out of the Services and connected to the website
        of the organization or company that maintains that website. Even if an
        affiliation exists between our Services and a third-party website, we
        exercise no control over and assume no responsibility for linked
        websites. Each of these linked websites maintains its own independent
        privacy and data collection policies and procedures. We encourage you to
        read the privacy policies of those other websites to learn how they
        collect, use, share, and secure your information before providing any
        Personal Data.
      </p>
      <h3>Retention</h3>
      <p>
        Except to the extent prohibited by law, and subject to this Policy, we
        will retain and use your Personal Data for as long as it is needed to
        provide you with any Services, communications, information you have
        requested, or access to the Services, to document our business
        relationship with you, and as necessary to comply with our legal
        obligations, resolve disputes, and enforce our agreements. As soon as it
        is reasonable to assume your Personal Data is no longer needed or
        required, we will cease to retain your Personal Data.
      </p>
      <h3>Your Rights</h3>
      <p>
        By law, you have a number of rights when it comes to your Personal Data.
        Further information and advice about your rights can be obtained from
        the data protection regulator in your country. The rights you have
        depend on the jurisdiction in which you are based.
      </p>
      <p>
        You may request for a copy of the Personal Data which we hold about you
        or information about the ways we use or disclose about your Personal
        Data. You may also ask us to correct or update your Personal Data, or
        withdraw your consent and request us to stop using or disclosing your
        Personal Data for any of the purposes stated in this Policy.
      </p>
      <p>
        You may do so by submitting your request in writing or via email at the
        contact details provided in the Contact Us section below.
      </p>
      <p>
        We usually act on requests and provide information free of charge, but
        we may charge a reasonable fee to cover our administrative costs of
        providing the information for baseless or excessive/repeated requests,
        or further copies of the same information. Alternatively, we may be
        entitled to refuse to act on the request in some circumstances. We may
        inquire and request for additional information/documents from you for
        the purpose of verifying your identity before processing your request.
        In the case where the information/documents provided does not clearly
        verify that you are the data subject or that you are authorised to
        submit the request, we reserve the right to deny that request.
      </p>
      <p>
        We will respond as soon as we can. Generally, this will be within one
        month from when we receive your request but, if the request is going to
        take longer to deal with, we will come back to you and let you know.
      </p>
      <p>Depending on applicable law, your rights may include the following:</p>
      <ul>
        <li>
          The right to be informed. You have the right to be provided with
          clear, transparent and easily understandable information about how we
          use your Personal Data and your rights. This is why we are providing
          you with the information in this Policy. If you have any additional
          questions, for example regarding transfers and locations of data or
          our legitimate interests basis, please contact us.
        </li>
        <li>
          The right of access. You have the right to obtain access to your
          Personal Data (if we are processing it), and certain other information
          (similar to that provided in this Policy) save for any applicable
          exemptions to do so under the data protection law. This is so you are
          aware and can check that we are using your Personal Data in accordance
          with data protection law.
        </li>
        <li>
          The right to rectification. You are entitled to have your information
          corrected if it is inaccurate or incomplete.
        </li>

        <li>
          The right to restrict processing. You have rights to ‘block’ or
          suppress further use of your Personal Data. We keep lists of people
          who have asked for further use of their information to be ‘blocked’ to
          make sure the restriction is respected in future.
        </li>
        <li>
          The right to data portability. You have rights to have your Personal
          Data transferred to another organization under certain conditions.
        </li>

        <li>
          The right to object to processing. You have the right to object to
          certain types of processing, including processing for direct
          marketing.
        </li>
        <li>
          The right to lodge a complaint. You have the right to lodge a
          complaint about the way we handle or process your Personal Data with
          your relevant data protection regulator.
        </li>
        <li>
          The right to withdraw consent. If you have given your consent to
          anything we do with your Personal Data, you have the right to withdraw
          your consent at any time (although if you do so, it does not mean that
          anything we have done with your Personal Data with your consent up to
          that point is unlawful). This includes your right to withdraw consent
          to us using your Personal Data for marketing purposes.
        </li>
      </ul>
      <h3>Changes to this Policy</h3>
      <p>
        This Policy is kept under regular review and may be updated from time to
        time. When we make changes to this Policy, we will change the "Updated"
        date above. If a material change is made to this Policy, we may choose
        to provide notice to you of such change, such as by posting notice of
        that change on the first page of this Policy or on our homepage, or by
        emailing your email address of record with us.
      </p>
      <h3>Changes to your Information</h3>
      <p>
        It is important that the information we hold about you is accurate and
        current. Please keep us informed as soon as possible if your Personal
        Data changes or is inaccurate during your relationship with us by using
        the contact details set out below.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any concern about our privacy practices, please contact us
        by email at support@softdebut.com.
      </p>
    </>
  );
}
